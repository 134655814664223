import { Link } from 'gatsby';
import React from 'react';

export default function RoundImageText({ imageurl, headline, text, url, big }) {
    return (
        <div className='flex flex-col items-center'>
            <Link to={url} aria-label={headline}>
                <img
                    className={
                        big
                            ? `h-44 w-44 border-4 border-white shadow-lg object-cover rounded-full`
                            : `h-32 w-32 border-4 border-white shadow-lg object-cover rounded-full`
                    }
                    src={imageurl}
                    alt={headline}
                />
            </Link>
            <p className='mb-2 mt-5 text-lg font-semibold'>{headline}</p>
            <span className='text-center text-sm text-gray-700'>{text}</span>
        </div>
    );
}
