import { Link } from 'gatsby';
import React from 'react';
import Container from '../components/Container';
import RoundImageText from '../components/RoundImageText';
import SEO from '../components/SEO';

export default function Index() {
    return (
        <Container>
            <SEO title='' />
            <h1 className='text-center leading-tight mb-14'>
                Willkommen auf den Seiten der
                <br /> rogamus-Stiftung
            </h1>
            <p className='leading-loose text-center mb-14'>
                Die gemeinnützige rogamus-Stiftung wurde 2017 durch das
                Erzbistum Köln gegründet. Ihre Aufgaben sind die{' '}
                <b>
                    Förderung von geistlichen Berufungen, die Betreuung der
                    rogamus Gebetsgemeinschaft sowie die Sorge für die
                    Anbetungskapelle im Maternushaus.
                </b>{' '}
                Das Gründungskapital der Stiftung stammt aus zweckgebundenen
                Nachlässen an den Erzbischöflichen Stuhl und aus Privatspenden.
            </p>

            <div className='flex flex-col-reverse sm:flex-row overflow-hidden bg-white rounded-lg shadow-navbar border-yellow-400 border-l-4 mb-14'>
                <div className='sm:w-9/12 px-5 py-6 sm:p-7 sm:pl-12 align-self-stretch flex-grow-0'>
                    <p className='text-yellow-500 leading-none mb-2'></p>
                    <h2 className='text-gray-900 mt-0'>
                        Weltgebetstag für geistliche Berufungen 2021
                    </h2>
                    <p className='text-gray-900 leading-7 mb-5'>
                        Wir laden Sie ein am 24./25. April 2021, dem
                        Weltgebetstag für geistliche Berufungen, in vielen
                        Kirchen unseres Erzbistums 24h für eine geistliche
                        Erneuerung und geistliche Berufungen zu beten. Unter dem
                        Leitwort „Der, der Euch beruft, ist treu!“ (1 Thess
                        5,24) werden wir uns vor dem Eucharistischen Herrn
                        versammeln und unsere Bitten und Nöte vor IHN tragen.
                        <br />
                        <strong>
                            Bitte helfen Sie durch Ihre Teilnahme am
                            Weltgebetstag für geistliche Berufungen, ein Zeichen
                            des Aufbruchs und der Erneuerung zu setzen!
                        </strong>
                    </p>
                    <Link to='/weltgebetstag-2021' className='btn'>
                        Zu den Orten
                    </Link>
                </div>
                <div
                    className='sm:w-3/12 sm:h-auto w-full h-40  bg-center bg-cover'
                    style={{
                        backgroundImage: `url('https://content.rogamus.de/wp-content/uploads/weltgebetstag_poster-scaled.jpg')`,
                    }}
                ></div>
            </div>

            <div className='grid grid-cols-1 sm:grid-cols-3 gap-10 mb-14'>
                <RoundImageText
                    imageurl='https://content.rogamus.de/wp-content/uploads/Monstranz.jpg'
                    url='/anbetungskapelle'
                    headline='Anbetungskapelle'
                    text='Das geistliche Zentrum der rogamus-Stiftung'
                    big={false}
                />

                <RoundImageText
                    imageurl='https://content.rogamus.de/wp-content/uploads/candle-2677854_960_720.jpg'
                    url='/stiftung'
                    headline='Stiftung'
                    text='Helfen Sie mit, die Zukunft der Kirche zu sichern'
                    big={false}
                />
                <RoundImageText
                    imageurl='https://content.rogamus.de/wp-content/uploads/beach-1868772_960_720.jpg'
                    url='/gebetsgemeinschaft'
                    headline='Gebetsgemeinschaft'
                    text='Beten wir um Berufungen'
                    big={false}
                />
            </div>

            <div className='bg-gray-100 rounded-lg text-center p-10 mb-14'>
                <h3 className='mb-10'>TEIL EINER STARKEN BEWEGUNG WERDEN</h3>
                <Link to='/jetzt-mitbeten' className='btn'>
                    Jetzt mitbeten
                </Link>
            </div>
            <div className='text-center' id='ansprechpartner'>
                <h3>Ansprechpartner</h3>
                <RoundImageText
                    imageurl='https://content.rogamus.de/wp-content/uploads/Regamy_Thallainathan_klein.jpg'
                    url='#ansprechpartner'
                    headline='Pfr. Regamy Thillainathan'
                    text='Sekretär der rogamus-Stiftung'
                    big={true}
                />
            </div>
        </Container>
    );
}
